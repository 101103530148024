
import request from '@/utils/request'
export function area_getData(data) {
  return request({
    url: '/area/data/getAllShowData',
    method: 'get',
    params:data
  })
}

export function area_getDynaData(data) {
  return request({
    url: '/area/data/getDynaData',
    method: 'get',
    params:data
  })
}
