<template>
    <div class="screenBox">
        <div class="TopTitle">
            馆藏文物预防性保护风险防控管理服务平台
        </div>
        <div class="MainBox">
            <div class="sideBox">
                <div class="leftTop">
                    <div class="sideTitle">文物风险状态</div>
                    <div class="containBox">
                        <pieChart :echartData="riskData"/>
                    </div>
                </div>
                <div class="leftTop leftCenter">
                    <div class="sideTitle">环境指标超限状况</div>
                    <div class="containBox">
                        <div class="operBar">
                            <el-radio-group v-model="typeSener" @change="changeRadio">
                                <el-radio-button label="02">温度</el-radio-button>
                                <el-radio-button label="01">湿度</el-radio-button>
                                <el-radio-button label="04">光照</el-radio-button>
                                <el-radio-button label="05">紫外</el-radio-button>
                                <el-radio-button label="03">CO₂</el-radio-button>
                                <el-radio-button label="06">VOC</el-radio-button>
                            </el-radio-group>
                        </div> 
                        <div class="barchart">
                            <barChart :Cdata="Cdata" :salvProName="salvProName" :TBdata="TBdata"/>
                        </div>
                    </div>
                </div>
                <div class="leftTop">
                    <div class="sideTitle">功能应用统计</div>
                    <div class="containBox containDis">
                        <div class="itemBox">
                            <div class="topLine">采样次数 <span class="value"> {{ sample }}</span></div>
                            <div class="bottomLine">
                                <span class="tips"></span>
                                今日达标率 {{areaRadio}}%
                            </div>
                        </div> 
                        <div class="itemBox">
                            <div class="topLine">设备数量 <span class="value">{{ devCount }}</span></div>
                            <div class="bottomLine">
                                <span class="tips"></span>
                                在线率 {{  ((devOnline/devCount)*100).toFixed(0)  }}%
                            </div>
                        </div> 
                        <div class="itemBox">
                            <div class="topLine">功能应用数量</div>
                            <div class="bottomLine"> <span class="value"> {{ onlineUse }}</span></div>
                        </div> 
                        <div class="itemBox">
                            <div class="topLine">离线应用数量</div>
                            <div class="bottomLine">
                                <span class="value">
                                    {{ offlineUse }}
                                </span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="centerBox">
                <div class="CB-top">
                    <div class="CB-topTbox">
                        <div class="CB-item CB-item2">
                            <div class="icon"></div>
                            <div class="textBox">
                                <div>在线站点数量</div>
                                <div class="textValue"><span class="textNum">{{ mapData.length }}</span>个</div>
                            </div>
                        </div>
                        <div class="CB-item CB-item3">
                            <div class="icon"></div>
                            <div class="textBox">
                                <div>在线文物数量</div>
                                <div class="textValue"><span class="textNum">{{ sumRelic }}</span>个</div>
                            </div>
                        </div>
                    </div>
                    <mapChart :data="mapData" :areaName="areaName"/>
                </div>
                <div class="CB-bottom">
                    <div class="sideTitle centerTitle">当前告警详情</div>
                    <div class="containBox centerContain">
                        <div class="LineBox">
                            <div class="time">时间</div>
                            <div class="posi">位置</div>
                            <div class="relic">文物</div>
                            <div class="fact">原因</div>
                            <div class="status">状态</div>
                        </div>
                        <vue3-seamless-scroll :list="listWarning" class="seamless-warp" :step="0.5" :hover="true">
                            <div class="LineBox" v-for="(item, index) in listWarning" :key="index">
                                <div class="time">{{ item.createtime }}</div>
                                <div class="posi">{{ item.siteid }}-{{ item.positionname }}</div>
                                <div class="relic">{{ item.relicname }}</div>
                                <div class="fact">{{ item.detail }}</div>
                                <div class="status">
                                    <el-tag v-if="item.status == 1" type="success">已处理</el-tag>
                                    <el-tag v-if="item.status == 0" type="warning">未处理</el-tag>
                                    <el-tag v-if="item.status == 2" type="danger">超时未处理</el-tag>
                                </div>
                            </div>
                        </vue3-seamless-scroll>
                    </div>
                </div>
            </div>
            <div class="sideBox">
                <div class="leftTop">
                    <div class="sideTitle">风险预警状态</div>
                    <div class="containBox rightTop">
                        <div class="RT-item">
                            <div class="RIitem-box">
                                <img src="../../assets/screenCountry/风险预警数量.png" />
                                <div class="RIitem-text">
                                    <div>风险预警数量</div>
                                    <div class="valuebox">{{  alarmConnt  }}<span class="unit">个</span></div>
                                </div>
                            </div>
                            <div class="RIitem-chart">
                                近24小时
                                <!-- <roundChart :data="museumRate" color="#FFB516"/> -->
                            </div>
                        </div>
                        <div class="RT-item">
                            <div class="RIitem-box">
                                <img src="../../assets/screenCountry/风险评估数量.png" />
                                <div class="RIitem-text">
                                    <div>风险评估数量</div>
                                    <div class="valuebox">{{ sumRelic }}<span class="unit">个</span></div>
                                </div>
                            </div>
                            <div class="RIitem-chart">
                                近24小时
                                <!-- <roundChart :data="relicRate" color="#25FFA5"/> -->
                            </div>
                        </div>
                        <div class="RT-item">
                            <div class="RIitem-box">
                                <img src="../../assets/screenCountry/风险处置数量.png" />
                                <div class="RIitem-text">
                                    <div>风险处置数量</div>
                                    <div class="valuebox">{{ alarmUndealConnt }}<span class="unit">个</span></div>
                                </div>
                            </div>
                            <div class="RIitem-chart">
                                近48小时
                                <!-- <roundChart :data="relicRate" color="#25FFA5"/> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="leftTop  leftCenter">
                    <div class="sideTitle">气候环境状况</div>
                    <div class="containBox">
                        <div class="operBar">
                            <el-radio-group v-model="typeSener2" @change="changeRadio2">
                                <el-radio-button label="02">温度</el-radio-button>
                                <el-radio-button label="01">湿度</el-radio-button>
                                <el-radio-button label="04">光照</el-radio-button>
                                <el-radio-button label="05">紫外</el-radio-button>
                                <el-radio-button label="03">CO₂</el-radio-button>
                                <el-radio-button label="06">VOC</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div class="barchart">
                            <floatBar 
                            :wdminList="wdminList" 
                            :wdmaxList="wdmaxList" 
                            :yAxis="wdyAxis"/>
                        </div> 
                    </div>
                </div>
                <div class="leftTop">
                    <div class="sideTitle">区域预警对比</div>
                    <div class="containBox">
                        <normalBar :dataX="warningDataX" :dataY="warningDataY"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import pieChart from './Modules/pieChart.vue'
import barChart from './Modules/barChart.vue'
import deviceChart from './Modules/deviceChart.vue'
import roundChart from './Modules/roundChart.vue'
import floatBar from './Modules/floatBar.vue'
import normalBar from './Modules/normalBar.vue'
import mapChart from './Modules/mapChart.vue'
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
import { area_getData, area_getDynaData } from '@/api/areaScreenNew.js'
import { mapGetters } from 'vuex'
export default{
    components:{
        pieChart,
        barChart,
        deviceChart,
        roundChart,
        floatBar,
        normalBar,
        mapChart,
        Vue3SeamlessScroll,
    },
    computed:{
        ...mapGetters(['user']),
    },
    data(){
        return{
            areaName:"",
            wdyAxis:[],
            wdmaxList:[],
            wdminList:[],

            warningDataX:[],
            warningDataY:[],
            mapData:[],
            typeSener:'02',
            typeSener2:'02',
            listWarning:[],
            
            sumRelic:0,
            onlineMuseum:0,

            alarmConnt:0,
            alarmUndealConnt:0,
            areaRadio:0, //今日达标率->昨日达标率
            onlineUse:0, //功能应用数
            offlineUse:0, //离线应用数量
            sample:0, //采样次数
            devCount:0, //所有设备
            devOnline:0, //在线设备

            riskData:[
                {value: 0,name: "优"},
                {value: 0,name: "良"},
                {value: 0,name: "中"},
                {value: 1,name: "差"}
            ],
            relicSum:0,
            museumRate:0,
            relicRate:0,
            chartData:{},
            chartData2:{},
            Cdata:[], //近24小时
            TBdata:[], //同比
            salvProName:[], //博物馆名称
        }
    },
    mounted(){
        this.areaGetData()
    },
    methods:{
        areaGetData(){
            if(this.user.zoneTree.type == 3){
                this.$router.push('/site/siteHome')
                return 
            }
            let query = {
                code:this.user.zoneTree.type==1?this.$route.query.id:this.user.zoneTree.code
            }
            area_getData(query).then(r=>{
                this.areaName = r.data.areaName
                this.listWarning = r.data.alarms

                this.chartData2 = r.data.interval
                this.changeRadio2(this.typeSener2)

                let countAlarm = r.data.countAlarm
                this.warningDataX = countAlarm.map(i=> {return i.NAME}) 
                this.warningDataY = countAlarm.map(i=> {return i.num })
                this.mapData = r.data.zone.map(i=>{
                    return {name: i.name,value: [i.lon, i.lat], datas: i.code}
                })
                
                this.areaRadio = r.data.areaRadio
                this.onlineUse = r.data.onlineUse
                this.offlineUse = r.data.offlineUse
                this.devCount = r.data.allDevice
                this.devOnline = r.data.onlineDevice
                this.alarmConnt = r.data.alarmConnt

                this.onlineMuseum = r.data.onlineMuseum
                this.alarmUndealConnt = r.data.alarmUndealConnt

                let countRisk = r.data.countRisk
                this.riskData = [
                    {value:countRisk.excellent, name: "优"},
                    {value:countRisk.good, name: "良"},
                    {value:countRisk.average, name: "中"},
                    {value:countRisk.poor, name: "差"}
                ]

                this.sumRelic = countRisk.excellent + countRisk.good + countRisk.average + countRisk.poor
                this.museumCount = r.data.onlineMuseum

                this.museumRate = Number(((this.mapData.length/r.data.museumNum) * 100).toFixed(2));
                this.relicRate = Number(((this.relicSum/r.data.culturalRelics) * 100).toFixed(2));

                this.chartData = r.data.durationRatio 
                this.changeRadio(this.typeSener)
            })
            area_getDynaData(query).then(r=>{
                this.sample = r.data.sample
            })
        },
        changeRadio(val){
            this.typeSener = val
            this.salvProName = this.chartData[`${this.typeSener}by`].map(i=>{return i.name })
            this.TBdata = this.chartData[`${this.typeSener}by`].map(i=>{return i.radio })
            this.Cdata = this.chartData[`${this.typeSener}y`].map(i=>{return i.radio })
        },
        changeRadio2(val){
            this.typeSener2 = val
            this.wdyAxis = this.chartData2[this.typeSener2].map(i=> {return i.NAME})
            this.wdmaxList = this.chartData2[this.typeSener2].map(i=> {return i.max=='Null'?0:i.max})
            this.wdminList = this.chartData2[this.typeSener2].map(i=> {return i.min=='Null'?0:i.min})
        }
    }
}
</script>
<style lang="scss" scoped>
.screenBox{
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    box-sizing: border-box;
    background-image: url('../../assets/screenCountry/screenBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: white;
}
.TopTitle{
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: url('../../assets/screenCountry/topBg.png');
    background-size:contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 30px;
    color: #ffffff;
    line-height: 80px;
    text-align: center;
    font-weight: 500;
}
.MainBox{
    width: 100%;
    height: calc(100% - 0px);
    display: flex;
    justify-content: space-between;
    .sideBox{
        width: 440px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
    .centerBox{
        width: calc(100% - 900px);
        height: 100%;
        .CB-top{
            width: 100%;
            height: 70%;
            position: relative;
            .CB-topTbox{
                position: absolute;
                z-index: 5;
                width: 100%;
                height: 61px;
                top: 50px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                .CB-item{
                    width: 200px;
                    height: 100%;
                    background-color: #3589ff5e;
                    border-radius: 40px;
                    overflow: hidden;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .textBox{
                        width: calc(100% - 70px);
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-direction: column;
                        .textValue{
                            color: #35DBFF;
                        }
                        .textNum{
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }
                    .icon{
                        width: 70px;
                        height: 100%;
                    }
                }
                .CB-item1{
                    background-image: url('../../assets/screenCountry/top-areas.png');   
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .CB-item2{
                    background-image: url('../../assets/screenCountry/top-risk.png');   
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .CB-item3{
                    background-image: url('../../assets/screenCountry/top-devices.png');   
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        .CB-bottom{
            width: 100%;
            height: 30%;
            .centerTitle{
                background-image: url('../../assets/screenCountry/titleBglong.png');
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }
            .centerContain{
                background-color: rgba($color: #000000, $alpha: 0.5);
                .LineBox{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 30px;
                    .time{
                        width: 15%;
                        text-align: center;
                    }
                    .posi{
                        width: 30%;
                        text-align: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .relic{
                        width: 15%;
                        text-align: center;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .fact{
                        width:25%;
                        text-align: center;
                        color: white;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .status{
                        width: 15%;
                    }
                }
                .seamless-warp {
                    box-sizing: border-box;
                    width: 100%;
                    margin-top: 10px;
                    height: calc(100% - 50px);
                    font-size: 14px;
                    color: #35DBFF;
                    overflow: hidden;
                }
            }
        }
    }

}
.leftTop{
    width: 100%;
    height: 30%;
}
.leftCenter{
    width: 100%;
    height:calc(40% - 20px);
}
.sideTitle{
    width: 100%;
    height: 40px;
    background-image: url('../../assets/screenCountry/titleBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-size: 20px;
    color: white;
    text-align: center;
    line-height: 40px;
}
.containBox{
    width: calc(100% - 30px);
    margin: 0 auto;
    height: calc(100% - 40px);
    background-color: #00000013;
    border: 1px solid #2ba3ff21;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 10px 20px;
}
.operBar{
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.barchart{
    width: 100%;
    height: calc(100% - 35px);
}
.containDis{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .itemBox{
        width:calc(50% - 5px);
        height:calc(50% - 10px);
        background-image:url('../../assets/screenCountry/框.png');
        background-size:100% 100%;
        background-repeat:no-repeat;
        display:flex;
        justify-content:center;
        align-items:center;
        flex-direction:column;
        
        .topLine{
            padding:5px 20px;
            color:#50EBFA;
            font-size:16px;
            font-weight:700;
            border-bottom:1px solid #50EBFA30;
           
        }
        .bottomLine{
            padding:5px 20px;
            font-size:14px;
            color:white;
            .tips{
                display:inline-block;
                width:8px;
                height:8px;
                border-radius:50%;
                background-color:rgb(22, 255, 22);
            }
        }
        .value{
            color:#50EBFA;
            font-size:18px; 
            font-weight:700;
        }
    }
}
.rightTop{
   display: flex;
   justify-content: space-around;
   align-items: center; 
   flex-direction: column;
   .RT-item{
        width: 100%;
        height: 32%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .RIitem-box{
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img{
                width: 65px;
                height: 100%;
                object-fit: contain;
            }
            .RIitem-text{
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-direction: column; 
                margin-left: 10px; 
                .valuebox{
                    font-size: 22px;
                    color: aqua;
                }
                .unit{
                    font-size: 16px;
                }
            }
        }
        .RIitem-chart{
            font-size:14px;
        }
   }
   
}
/deep/.el-radio-button__inner{
    background: #04163ac4;
    border: 1px solid rgb(37, 118, 239);
    color: rgb(235, 235, 235);
}
</style>